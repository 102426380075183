// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {Plugins} from '@capacitor/core';

const {Device} = Plugins;

export const environment = {
    production: false
};

export let APP_SHORT_NAME = 'CM';
export let APP_NAME = 'Clean Manager';
export let SOLUTION_URL = 'https://cleanmanager.arithmetic.fr';
export let SERVER_URL = 'https://cleanmanager.arithmetic.fr';

export const fillAppInfos = async () => {
    SERVER_URL = SOLUTION_URL;
};
